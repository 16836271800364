import React, { useEffect, useState } from 'react'
import landing from '../assest/landing.png'
import Aos from 'aos';
import "aos/dist/aos.css"
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function Home () {
    const [home, setHome] = useState([]);
    const { t, i18n } = useTranslation();

    i18next.on('languageChanged', function (lng) {
        // تحديث اتجاه الصفحة بناءً على اللغة الحالية
        document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
    });

    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }

        Aos.init();

        // fetch home
        const fetchLogo = async () => {
            const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?logo`)
            const data = await res.json()
            return setHome(data);
        }
        fetchLogo()
    }, [])
    return (
        <section className='home' id='home'>
            <div className="blur1"></div>
            <div className="blur2"></div>
            <div className="container">
                <div className="social">
                    <ul>
                        <li><a target='_blank' href="https://www.facebook.com/AlnasrLF/"><i className="fa fa-facebook-f"></i></a></li>
                        <li><a target='_blank' href="https://api.whatsapp.com/send?phone=+201143939242&amp;text=مرحبا, استاذ عبدالله, اريد التحدث معك بخصوص استشارة قانونية !"><i className="fa fa-whatsapp"></i></a></li>
                    </ul>
                </div>
                <div className="content">
                    <div data-aos="fade-left" data-aos-duration="3000" className="text-area">
                        <div className="text">
                            <h1> <span>{i18n.language == "ar" ? "مؤسسة النصر للمحاماة" : "AlNasr Law Firm"}</span> <br /></h1>
                            <p>{i18n.language == "ar" ? "هل تبحث عن خدمات قانونية ذات جودة عالية واحترافية؟ اكتشف مؤسسة النصر للمحاماة! نحن هنا لتلبية كافة احتياجاتك القانونية بأعلى مستوى من التخصص والدقة. اعتمد على فريق خبرائنا لضمان حلول قانونية فعالة وموثوقة بشأن جميع قضاياك. اختر الجودة والاحترافية، اختر مؤسسة النصر للمحاماة." : "Are you looking for high quality and professional legal services Discover Al Nasr law firm! We are here to meet all your legal needs with the highest level of specialization and accuracy. Rely on our team of experts to ensure effective and reliable legal solutions on all your issues. Choose quality and professionalism, choose Al Nasr law firm."}</p>
                            <div className="btns">
                                <button class="button type1">
                                    <span class="btn-txt"><a href="#services">{i18n.language == "ar" ? "كل الخدمات" : "All Services"}</a></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-right" data-aos-duration="3000" className="image-area">
                        <div className="image">
                            <img src={landing} alt="" />
                        </div>
                    </div>
                </div>

                <div className="reviews">
                    <div class="counter">
                        <ul>
                            <li >
                                <div class="single-counter">
                                    <div class="content-review">
                                        <div class="number">
                                            <span className='icon'><svg xmlns="http://www.w3.org/2000/svg" height={"2rem"} viewBox="0 0 384 512"><path d="M297.2 248.9C311.6 228.3 320 203.2 320 176c0-70.7-57.3-128-128-128S64 105.3 64 176c0 27.2 8.4 52.3 22.8 72.9c3.7 5.3 8.1 11.3 12.8 17.7l0 0c12.9 17.7 28.3 38.9 39.8 59.8c10.4 19 15.7 38.8 18.3 57.5H109c-2.2-12-5.9-23.7-11.8-34.5c-9.9-18-22.2-34.9-34.5-51.8l0 0 0 0c-5.2-7.1-10.4-14.2-15.4-21.4C27.6 247.9 16 213.3 16 176C16 78.8 94.8 0 192 0s176 78.8 176 176c0 37.3-11.6 71.9-31.4 100.3c-5 7.2-10.2 14.3-15.4 21.4l0 0 0 0c-12.3 16.8-24.6 33.7-34.5 51.8c-5.9 10.8-9.6 22.5-11.8 34.5H226.4c2.6-18.7 7.9-38.6 18.3-57.5c11.5-20.9 26.9-42.1 39.8-59.8l0 0 0 0 0 0c4.7-6.4 9-12.4 12.7-17.7zM192 128c-26.5 0-48 21.5-48 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-44.2 35.8-80 80-80c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0 384c-44.2 0-80-35.8-80-80V416H272v16c0 44.2-35.8 80-80 80z" /></svg></span>
                                        </div>
                                        <p>{i18n.language == "ar" ? "استشارات" : "Consulting"}</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="single-counter">
                                    <div class="content-review">
                                        <div class="number">
                                            <span className='icon'><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M290.8 48.6l78.4 29.7L288 109.5 206.8 78.3l78.4-29.7c1.8-.7 3.8-.7 5.7 0zM136 92.5V204.7c-1.3 .4-2.6 .8-3.9 1.3l-96 36.4C14.4 250.6 0 271.5 0 294.7V413.9c0 22.2 13.1 42.3 33.5 51.3l96 42.2c14.4 6.3 30.7 6.3 45.1 0L288 457.5l113.5 49.9c14.4 6.3 30.7 6.3 45.1 0l96-42.2c20.3-8.9 33.5-29.1 33.5-51.3V294.7c0-23.3-14.4-44.1-36.1-52.4l-96-36.4c-1.3-.5-2.6-.9-3.9-1.3V92.5c0-23.3-14.4-44.1-36.1-52.4l-96-36.4c-12.8-4.8-26.9-4.8-39.7 0l-96 36.4C150.4 48.4 136 69.3 136 92.5zM392 210.6l-82.4 31.2V152.6L392 121v89.6zM154.8 250.9l78.4 29.7L152 311.7 70.8 280.6l78.4-29.7c1.8-.7 3.8-.7 5.7 0zm18.8 204.4V354.8L256 323.2v95.9l-82.4 36.2zM421.2 250.9c1.8-.7 3.8-.7 5.7 0l78.4 29.7L424 311.7l-81.2-31.1 78.4-29.7zM523.2 421.2l-77.6 34.1V354.8L528 323.2v90.7c0 3.2-1.9 6-4.8 7.3z"></path></svg></span>
                                        </div>
                                        <p>{i18n.language == "ar" ? "خدمات متنوعة" : "Various Services"}</p>
                                    </div>
                                </div>
                            </li>
                            <li >
                                <div class="single-counter">
                                    <div class="content-review">
                                        <div class="number">
                                            <span className='icon'><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 288 512"><path d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z" /></svg></span>
                                        </div>
                                        <p>{i18n.language == "ar" ? "اسعار تنافسية" : "Competitive Prices"}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Home
