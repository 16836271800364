import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory, useNavigate } from 'react-router-dom'
import service from '../assest/des1.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { fetchColor } from '../rtk/slices/color-slice';
import { fetchServices } from '../rtk/slices/services-slice';
import { fetchEmployees } from '../rtk/slices/employees-slice';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function Employee () {
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي
    const { id } = useParams();

    const navigate = useNavigate();
    // توجيه المستخدم إلى القسم "Contact"
    const handleButtonClick = () => {
        navigate('/#contact');
    };

    i18next.on('languageChanged', function (lng) {
        // تحديث اتجاه الصفحة بناءً على اللغة الحالية
        document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
    });
    const dispatch = useDispatch();
    const employees = useSelector((state) => state.employees.employees);
    const color = useSelector((state) => state.color.color);


    const { t, i18n } = useTranslation();

    const employees_en = employees.map(em_en => {
        if (i18n.language == "en") {
            return ({
                "id": em_en.id,
                "name": em_en.name_en,
                "img": em_en.img,
                "jop": em_en.jop_en
            })
        }
        return em_en;
    })

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }


    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }

        // fetch color
        dispatch(fetchColor());

        // fetch services
        dispatch(fetchEmployees());

    }, [])

    return (
        <section className='projects service' id='projects'>
            <div className="contact-menu">
                <a target="_blank" href="https://api.whatsapp.com/send?phone=+201143939242&amp;text=مرحبا, استاذ عبدالله, اريد التحدث معك بخصوص استشارة قانونية !" class="whatsapp_link">
                </a>
            </div>

            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "الموظف" : "Employee"}</span>
                    <p style={{ textTransform: "capitalize" }}>{i18n.language == "ar" ? "الموظف" : "Employee"}</p>
                </h2>

                {employees_en.map((service) => {
                    return (
                        service['id'] == id ?
                            <div className="card-service">
                                <div className="img">
                                    <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${service.img}`} alt="" />
                                </div>
                                <div className="content">
                                    <div className="box">
                                        <h3>{service.name}</h3>
                                        <p>
                                            {service.jop.split(',').map((what) => {
                                                return (
                                                    <>
                                                        <span>{what}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                    </div>
                                    {/* <div className="box">
                                        <h3>المميزات</h3>
                                        <p>
                                            {service.features_service.split(',').map((feature) => {
                                                return (
                                                    <>
                                                        <span>{feature}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                    </div>
                                    <div className="box">
                                        <h3>الاسعار</h3>
                                        <p className='priceP'>
                                            {service.prices_service.split(',').map((price) => {
                                                return (
                                                    <>
                                                        <span className='priceSpan'>{price}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                    </div> */}
                                    {/* <div style={{ textAlign: "center" }} class="btns"><button class="button type1"><span class="btn-txt"><Link to={`/contact`}>اطلب الان</Link></span></button></div> */}
                                    {/* m o h a m e d  a r a b y  0 1 0 1 2 6 4 8 5 3 7*/}

                                </div>
                            </div>
                            : null
                    )
                })}


                <Link className='exit' to={`/`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default Employee
