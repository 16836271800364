import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServices } from '../rtk/slices/services-slice'
import { fetchColor } from '../rtk/slices/color-slice'
import { fetchProjects } from '../rtk/slices/projects-slice'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

function All_certificates () {

    window.scrollTo(0, 0); // العودة إلى الجزء العلوي

    i18next.on('languageChanged', function (lng) {
        // تحديث اتجاه الصفحة بناءً على اللغة الحالية
        document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
    });

    const color = useSelector((state) => state.color.color);
    const dispatch = useDispatch();
    const projects = useSelector((state) => state.projects.projects);
    const [servicesSearch, setServicesSearch] = useState(projects);


    const { t, i18n } = useTranslation();

    const customers_en = projects.map(cu_en => {
        if (i18n.language == "en") {
            return ({
                "id": cu_en.id,
                "title": cu_en.title_en,
            })
        }
        return cu_en;
    })

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }

    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }

        // fetch color
        dispatch(fetchColor());

        // fetch services
        dispatch(fetchProjects())

    }, [])

    return (
        <section className='projects ' id='projects'>
            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "العملاء" : "Customers"}</span>
                    <p>{i18n.language == "ar" ? "العملاء" : "Customers"}</p>
                </h2>
                {/* <div className="certificates">
                    <form style={{ marginTop: "3rem" }}>
                        <div className="input-box">
                            <div className="input-field">
                                <input className='myInput' name='name' type="text" onChange={(e) => filterService(e.target.value)} placeholder='اسم المشروع' required />
                                <div className="icon"><i class="fa fa-search"></i></div>
                            </div>
                        </div>
                        <div class="btn-box btns"><button class="button type1"><span class="btn-txt"><a>بحث</a></span></button></div>
                    </form>
                </div> */}

                <div className="cards">

                    {
                        customers_en.map((project, index) => {
                            return (
                                <div target='_blank' href={`${project.url}`} className="card" key={project.id}>
                                    <img style={{ width: "140px", borderRadius: ".2rem" }} src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${project.logo}`} alt="" />
                                    <h3>{project.title}</h3>
                                    <h3 style={{ color: "#2196F3" }}>{project.phone}</h3>
                                </div>
                            )
                        })
                    }

                </div>

                <Link className='exit' to={`/`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default All_certificates
