import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServices } from '../rtk/slices/services-slice'
import { fetchColor } from '../rtk/slices/color-slice'
import { fetchShow } from '../rtk/slices/show-slice'
import { fetchBlogs } from '../rtk/slices/blogs-slice'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

function All_Blogs () {
    i18next.on('languageChanged', function (lng) {
        // تحديث اتجاه الصفحة بناءً على اللغة الحالية
        document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
    });

    const color = useSelector((state) => state.color.color);
    const dispatch = useDispatch();
    const blogs = useSelector((state) => state.blogs.blogs);


    const { t, i18n } = useTranslation();

    const blogs_en = blogs.map(blo_en => {
        if (i18n.language == "en") {
            return ({
                "id": blo_en.id,
                "image": blo_en.image,
                "title": blo_en.title_en,
                "description": blo_en.description_en
            })
        }
        return blo_en;
    })


    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }

    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }

        // fetch color
        dispatch(fetchColor());
        // fetch show
        dispatch(fetchShow());

        // fetch services
        dispatch(fetchBlogs());

    }, [])

    return (
        <section className='memories projects blogs' id='projects'>
            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "التدوينات" : "Blogs"}</span>
                    <p>{i18n.language == "ar" ? "التدوينات" : "Blogs"}</p>
                </h2>

                {/* <form style={{ marginTop: "3rem" }}>
                    <div className="input-box">
                        <div className="input-field">
                            <input className='myInput' name='name' type="text" onChange={(e) => filterService(e.target.value.toLowerCase())} placeholder='اسم مدونة مثال: برمجة' required />
                            <div className="icon"><i class="fa fa-search"></i></div>
                        </div>
                    </div>
                    <div class="btn-box btns"><button class="button type1"><span class="btn-txt"><a>بحث</a></span></button></div>
                </form> */}

                <div className="cards">

                    {
                        blogs_en.map((service) => {
                            return (
                                <Link to={`/blogs/blog/${service.id}`} className="card" key={service.id}>
                                    <div style={{ textAlign: "center" }}>
                                        <img style={{ width: "100%", maxWidth: "19rem", borderRadius: ".5rem" }} src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${service.image}`} alt="" />
                                    </div>
                                    <h4><span style={{ color: "var(--main-color-opacity-100)" }}>{i18n.language == "ar" ? "العنوان:- " : "Title:- "} </span>{service.title}</h4>
                                    <p>{service.description.substring(0, 100)}..</p>
                                </Link>
                            )
                        })
                    }

                </div>

                <Link className='exit' to={`/`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default All_Blogs
