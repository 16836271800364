import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

function Contact () {

    const { t, i18n } = useTranslation();


    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
    }, [])
    return (
        <section className='contact' id='contact'>
            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "اتصل بنا" : "Contact Us"}</span>
                    <p>{i18n.language == "ar" ? "اتصل بنا" : "Contact Us"}</p>
                </h2>
                <div className="contact-content">
                    <div className="contact-social">
                        <span>
                            <a href='https://www.facebook.com/AlnasrLF/' target='_blank' class="btn-sosial">
                                <span class="svgContainer">
                                </span>
                                <i>  <svg fill='#fff' xmlns="http://www.w3.org/2000/svg" height={"2rem"} viewBox="0 0 512 512"><path d="M256.6 8C116.5 8 8 110.3 8 248.6c0 72.3 29.7 134.8 78.1 177.9 8.4 7.5 6.6 11.9 8.1 58.2A19.9 19.9 0 0 0 122 502.3c52.9-23.3 53.6-25.1 62.6-22.7C337.9 521.8 504 423.7 504 248.6 504 110.3 396.6 8 256.6 8zm149.2 185.1l-73 115.6a37.4 37.4 0 0 1 -53.9 9.9l-58.1-43.5a15 15 0 0 0 -18 0l-78.4 59.4c-10.5 7.9-24.2-4.6-17.1-15.7l73-115.6a37.4 37.4 0 0 1 53.9-9.9l58.1 43.5a15 15 0 0 0 18 0l78.4-59.4c10.4-8 24.1 4.5 17.1 15.6z" /></svg>
                                </i>
                                <span class="BG-social"></span>
                            </a>
                        </span>
                        <span>
                            <a href='https://www.facebook.com/abdalla.aboelnasr?mibextid=LQQJ4d' target='_blank' class="btn-sosial">
                                <span class="svgContainer">
                                </span>
                                <i class="fa fa-facebook-f"></i>
                                <span class="BG-social"></span>
                            </a>
                        </span>

                        <span>
                            <a href='https://api.whatsapp.com/send?phone=201143939242text=مرحبا, استاذ عبدالله, اريد التحدث معك بخصوص استشارة قانونية !' target='_blank' class="btn-sosial">
                                <span class="svgContainer">
                                </span>
                                <i class="fa fa-whatsapp"></i>
                                <span class="BG-social"></span>
                            </a>
                        </span>

                        <span>
                            <a href='tel:+201143939242' target='_blank' class="btn-sosial">
                                <span class="svgContainer">
                                </span>
                                <i class="fa fa-phone"></i>
                                <span class="BG-social"></span>
                            </a>
                        </span>

                        <span>
                            <a href='https://www.instagram.com/abdalla.aboelnasr?igsh=MWpta3luaTllYmk3eA%3D%3D&utm_source=qr' target='_blank' class="btn-sosial">
                                <span class="svgContainer">
                                </span>
                                <i class="fa fa-instagram"></i>
                                <span class="BG-social"></span>
                            </a>
                        </span>
                        <span>
                            <a href='https://www.linkedin.com/in/alnasar-lawfirm-1903b5273?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app' target='_blank' class="btn-sosial">
                                <span class="svgContainer">
                                </span>
                                <i class="fa fa-linkedin"></i>
                                <span class="BG-social"></span>
                            </a>
                        </span>
                        <span>
                            <a href='https://www.youtube.com/@AlnasarLawfirm' target='_blank' class="btn-sosial">
                                <span class="svgContainer">
                                </span>
                                <i class="fa fa-youtube"></i>
                                <span class="BG-social"></span>
                            </a>
                        </span>
                        <span>
                            <a href='https://t.me/ABDALLA_ABOELNASR' target='_blank' class="btn-sosial">
                                <span class="svgContainer">
                                </span>
                                <i class="fa fa-telegram"></i>
                                <span class="BG-social"></span>
                            </a>
                        </span>
                        <span>
                            <a href='https://www.tiktok.com/@alnasrlawfirm?_t=8kI0vfWkqMo&_r=1' target='_blank' class="btn-sosial">
                                <span class="svgContainer">
                                </span>
                                <i><svg fill='#fff' height={"2rem"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" /></svg></i>
                                <span class="BG-social"></span>
                            </a>
                        </span>

                    </div>
                </div>
                <div class="map">
                    <div className="des" style={{ padding: "1rem" }}>
                        <div style={{ color: "gray", fontSize: "1.4rem", paddingBottom: "1rem" }}>{i18n.language == "ar" ? "ساعات العمل: الأحد - الخميس: 9:00 ص إلى 6:00 م" : "Opening hours: Sunday-Thursday: 9:00 AM to 6:00 PM"}</div>
                        <label className='labelContact' htmlFor="address">{i18n.language == "ar" ? "العنوان" : "Address Info"}</label>
                        <div className="address">{i18n.language == "ar" ? "المكتب الرئيسي: شارع عثمان محمد تقاطع ش المديرية - برج الفيروز الدور الثاني  – طنطا" : "Head office: Osman Mohamed Street intersection of Directorate St.-Fayrouz Tower second floor-Tanta"}</div>
                        <div className="address">{i18n.language == "ar" ? "فرع الاسكندريه : برج زمزم رقم 17 – ابراج القوات المسلحه مكتب رقم 34 القبة" : "Alexandria branch: Zamzam Tower No. 17 -armed forces Towers Office No. 34 the dome"}</div>
                        <label className='labelContact' htmlFor="address">{i18n.language == "ar" ? "البريد الإالكتروني" : "Email"}</label>
                        <div className="email"><a href="mailto:abdalla.aboelnasr@icloud.com?subject=Website">abdalla.aboelnasr@icloud.com</a></div>
                        <label htmlFor="number" class="labelContact">{i18n.language == "ar" ? "رقم الهاتف" : "Phone Number"}</label>
                        <div htmlFor="number"><a href="tel:+201143939242">01143939242</a></div>
                        <div htmlFor="number"><a href="tel:+201143939242">0403349286</a></div>
                    </div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3427.6257847112347!2d30.996413600000007!3d30.78508069999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f7c9522cd43bcb%3A0x9120e6b9004d924b!2z2YXZg9iq2Kgg2LnYqNiv2KfZhNmE2Ycg2KfYqNmI2KfZhNmG2LXYsSDYp9mE2YXYrdin2YXZig!5e0!3m2!1sen!2seg!4v1708197570203!5m2!1sen!2seg"></iframe>
                </div>
            </div>
        </section>
    )
}

export default Contact
