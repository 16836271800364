import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchGallery = createAsyncThunk('gallerySlice/fetchGallery', async () => {
    const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?gallery`)
    const data = await res.json()
    return data;
})

const gallerySlice = createSlice({
    initialState: {
        gallery: [],
        loading: false,
        error: null,
    },
    name: 'gallerySlice',
    extraReducers: (builder) => {
        builder
            .addCase(fetchGallery.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchGallery.fulfilled, (state, action) => {
                state.loading = false;
                state.gallery = action.payload;
            })
            .addCase(fetchGallery.rejected, (state, action) => {
                state.loading = false;
                state.error = <p className='error-api'>The server is temporarily down Please try later!</p>;
            });
    },
})

export const { } = gallerySlice.actions;

export default gallerySlice.reducer;